import { MintPhaseModel } from '@/models/mint-phase-model'
import moment from 'moment'

export type MintType = 'pre-mint' | 'public-mint' | 'normal-mint' | 'airdrop'
export type MintStatus = 'upcoming' | 'ended' | 'live'
export type MintState = {
  currentPhase: MintType
  countdownTBA: boolean
  countdownText: string
  countdownDays: number
  countdownHours: number
  countdownMinutes: number
  countdownSeconds: number
  currentTime: moment.Moment
  mintChip: string
  timelineStep: number
  preMintStatus: MintStatus
  publicMintStatus: MintStatus
  airdropStatus: MintStatus
  // preMintWhitelistStarted: boolean
  saleStarted: boolean

  // showProcessRegister: boolean
  showProcessOptIn: boolean
  // showProcessMint: boolean
  showProcessLottery: boolean
  showProcessEnded: boolean
  showProcessWaiting: boolean
}

export const getMintState = (mint: MintPhaseModel, currentTime = moment()) => {
  const { preMintData, publicMintData, airdropData } = mint
  let countdownTargetDate = 0
  let timelineStep = 0
  let countdownText = ''
  let countdownTBA = false
  let mintChip = 'whitelist'
  let currentPhase: MintType = 'pre-mint'
  let preMintStatus: MintStatus = 'upcoming'
  let publicMintStatus: MintStatus = 'upcoming'
  let airdropStatus: MintStatus = 'upcoming'

  // let showProcessRegister = false
  let showProcessOptIn = false
  // let showProcessMint = false
  let showProcessWaiting = false
  let showProcessEnded = false
  let showProcessLottery = false
  // let preMintWhitelistStarted = true
  let saleStarted = false

  if (airdropData?.showTime && currentTime.isAfter(moment(airdropData.showTime))) {
    const { saleStartTime, saleEndTime } = airdropData
    if (!currentTime.isAfter(saleStartTime)) {
      countdownTargetDate = new Date(saleStartTime || '').getTime()
      countdownText = 'Airdrop opens in'
      countdownTBA = !!saleStartTime
      // showProcessRegister = true
      // showProcessWaiting = true
    } else if (!currentTime.isAfter(saleEndTime)) {
      countdownTargetDate = new Date(saleEndTime || '').getTime()
      countdownText = 'Airdrop ends in'
      countdownTBA = !!saleEndTime
      airdropStatus = 'live'
      saleStarted = true
      // showProcessMint = true
    } else {
      countdownTargetDate = new Date(saleEndTime || '').getTime()
      countdownText = 'Airdrop is closed!'
      timelineStep = 1
      airdropStatus = 'ended'
      showProcessEnded = true
    }
    currentPhase = 'airdrop'
    mintChip = 'airdrop'
    preMintStatus = 'ended'
  } else if (publicMintData?.showTime && currentTime.isAfter(moment(publicMintData.showTime))) {
    const { saleStartTime, saleEndTime } = publicMintData
    if (!currentTime.isAfter(saleStartTime)) {
      countdownTargetDate = new Date(saleStartTime || '').getTime()
      countdownText = 'The Sale opens in'
      countdownTBA = !!saleStartTime

      // showProcessRegister = true
      showProcessWaiting = true
    } else if (!currentTime.isAfter(saleEndTime)) {
      countdownTargetDate = new Date(saleEndTime || '').getTime()
      countdownText = 'The Sale ends in'
      countdownTBA = !!saleEndTime
      publicMintStatus = 'live'
      saleStarted = true

      // showProcessMint = true
    } else {
      countdownTargetDate = new Date(saleEndTime || '').getTime()
      countdownText = 'The Sale is closed!'
      timelineStep = 1
      publicMintStatus = 'ended'

      showProcessEnded = true
    }
    currentPhase = 'public-mint'
    mintChip = 'public'
    preMintStatus = 'ended'
  } else if (preMintData) {
    const {
      // whitelistStartTime,
      // whitelistEndTime,
      whitelistOptInStartTime,
      whitelistOptInEndTime,
      whitelistLotteryTime,
      saleStartTime,
      saleEndTime,
    } = preMintData

    // if (!currentTime.isAfter(whitelistStartTime)) {
    //   countdownTargetDate = new Date(whitelistStartTime || '').getTime()
    //   countdownText = 'Whitelist opens in'
    //   countdownTBA = !!whitelistStartTime
    //   preMintWhitelistStarted = false

    //   showProcessRegister = true
    // } else if (!currentTime.isAfter(whitelistEndTime)) {
    //   countdownTargetDate = new Date(whitelistEndTime || '').getTime()
    //   countdownText = 'Whitelist is ends in'
    //   countdownTBA = !!whitelistEndTime

    //   showProcessRegister = true
    // } else
    if (!currentTime.isAfter(whitelistOptInStartTime)) {
      countdownTargetDate = new Date(whitelistOptInStartTime || '').getTime()
      countdownText = 'Pre-mint opt-in opens in'
      countdownTBA = !!whitelistOptInStartTime
      showProcessOptIn = true
      // showProcessRegister = true
      showProcessWaiting = true
    } else if (!currentTime.isAfter(whitelistOptInEndTime)) {
      countdownTargetDate = new Date(whitelistOptInEndTime || '').getTime()
      countdownText = 'Pre-mint opt-in ends in'
      countdownTBA = !!whitelistOptInEndTime
      showProcessOptIn = true
    } else if (!currentTime.isAfter(whitelistLotteryTime)) {
      countdownTargetDate = new Date(whitelistLotteryTime || '').getTime()
      countdownText = 'The Lottery opens in'
      countdownTBA = !!whitelistLotteryTime
      timelineStep = 1
      showProcessLottery = true
      showProcessWaiting = true
    } else if (!currentTime.isAfter(saleStartTime)) {
      countdownTargetDate = new Date(saleStartTime || '').getTime()
      countdownText = 'The Sale opens in'
      countdownTBA = !!saleStartTime
      timelineStep = 1
      showProcessLottery = true
    } else if (!currentTime.isAfter(saleEndTime)) {
      countdownTargetDate = new Date(saleEndTime || '').getTime()
      countdownText = 'The Sale ends in'
      countdownTBA = !!saleEndTime
      timelineStep = 2
      saleStarted = true
      preMintStatus = 'live'
      // showProcessMint = true
    } else {
      countdownTargetDate = new Date(saleEndTime || '').getTime()
      countdownText = 'The Sale is closed!'
      timelineStep = 3
      preMintStatus = 'ended'
      showProcessEnded = true
    }
    currentPhase = 'pre-mint'
    mintChip = 'whitelist'
  }

  const now = Math.trunc(new Date().getTime() / 1000)
  const dateInSeconds = Math.trunc(countdownTargetDate / 1000)
  const countdownDays = Math.trunc((dateInSeconds - now) / 60 / 60 / 24)
  const countdownHours = Math.trunc((dateInSeconds - now) / 60 / 60) % 24
  const countdownMinutes = Math.trunc((dateInSeconds - now) / 60) % 60
  const countdownSeconds = (dateInSeconds - now) % 60

  const result: MintState = {
    currentPhase,
    countdownTBA,
    countdownText,
    countdownDays,
    countdownHours,
    countdownMinutes,
    countdownSeconds,
    currentTime,
    mintChip,
    timelineStep,
    preMintStatus,
    publicMintStatus,
    // showProcessRegister,
    showProcessOptIn,
    // showProcessMint,
    showProcessEnded,
    showProcessWaiting,
    showProcessLottery,
    // preMintWhitelistStarted,
    saleStarted,
    airdropStatus,
  }
  return result
}
