

























import { Observer } from 'mobx-vue'
import { Component, Provide, Vue, Watch } from 'vue-property-decorator'
import { MintViewModel } from '@/modules/mint/viewmodels/mint-viewmodel'

@Observer
@Component({
  components: {
    'min-nft-info': () => import('@/modules/mint/components/min-nft-info.vue'),
    'mint-description': () => import('@/modules/mint/components/mint-description.vue'),
    'mint-process': () => import('@/modules/mint/components/mint-process.vue'),
    'mint-timeline': () => import('@/modules/mint/components/mint-timeline.vue'),
    'claim-nft-dialog': () => import('@/modules/mint/dialogs/claim-nft-dialog.vue'),
    'company-footer': () => import('@/components/company-footer.vue'),
  },
})
export default class extends Vue {
  @Provide() vm = new MintViewModel()

  destroyed() {
    this.vm.destroy()
  }
}
