import { loadingController } from '@/components/global-loading/global-loading-controller'
import { snackController } from '@/components/snack-bar/snack-bar-controller'
import { NFTMinterHandler } from '@/handlers/nftMinterHandler'
import { apiService } from '@/services/api-services'
import { walletStore } from '@/stores/wallet-store'
import { action, computed, IReactionDisposer, observable, reaction, runInAction, when } from 'mobx'
import { asyncAction } from 'mobx-utils'
import { Subject, timer } from 'rxjs'
import { MintStore } from '../stores/mint-store'
import { takeUntil } from 'rxjs/operators'
import { getAssetByID } from '@/handlers/utils'
import { uniqBy } from 'lodash'
import walletsOpenOptIn from '@/constants/wallets-open-opt-in.json'

export type UserLocalState = {
  isWhitelistMember: boolean
  totalNFTMinted: boolean
  pendingNFTs: number[]
}

export type ContractState = {
  totalNFTMinted?: number
}
export class MintViewModel {
  @observable initFinalized = false
  @observable mintStore?: MintStore = undefined
  @observable openClaimNftDialog = false
  @observable whitelistRegisterLoading = false
  @observable currentUserLocalState = {}
  @observable isWhitelistRegistered = false
  @observable isWhitelistMember = false
  @observable isOptedInApplication = false
  @observable isOptInLoading = false
  @observable isContractWhitelistMember = false
  @observable totalYourNFTMinted = 0
  @observable contractStates!: ContractState
  @observable mintNumber = 1
  @observable minting = false
  @observable minted = false
  @observable pendingNFTs = []
  @observable claiming = false
  @observable mintNumberError = ''
  @observable totalPublicMintNftSold = 0
  @observable totalPreMintNftSold = 0
  @observable publicMintStatus = ''
  @observable totalMintParticipant = 0
  @observable claimingNftIndex = undefined

  nftMintHandler?: NFTMinterHandler

  private _unsubcrible = new Subject()
  private _disposers: IReactionDisposer[]
  walletsOpenOptIn = walletsOpenOptIn.wallets?.map((item) => item.toUpperCase())

  constructor() {
    this.init()
    this.fetchMintPhase()
    this._disposers = [
      reaction(
        () => walletStore.account,
        (account) => {
          if (account) {
            this.fetchUserState(account)
          }
        }
      ),
      reaction(
        () => this.mintState,
        (mintState) => {
          if (mintState && mintState.publicMintStatus === 'ended' && this.mintPhase?.id && !this.publicMintStatus) {
            this.publicMintStatus = 'ended'
            this.fetchMintTransactions(this.mintPhase?.id)
          }
        }
      ),
    ]

    timer(0, 30000)
      .pipe(takeUntil(this._unsubcrible))
      .subscribe(async () => {
        // runInAction(async () => {
        await when(() => !!this.nftMintHandler)
        const globalStateData = await this.nftMintHandler?.getGlobalStateData()
        runInAction(() => {
          this.contractStates = globalStateData as ContractState
        })
        // })
      })
  }

  @asyncAction *init() {
    try {
      this.nftMintHandler = new NFTMinterHandler()
    } catch (error) {
    } finally {
      this.initFinalized = true
    }
  }

  destroy() {
    this._unsubcrible.next()
    this._unsubcrible.complete()
    this._disposers.forEach((d) => d())
  }

  @asyncAction *fetchMintPhase() {
    try {
      loadingController.increaseRequest()
      const mintPhases = yield apiService.mintingPhases.find({}, { _limit: 1 })
      if (mintPhases[0]) {
        this.mintStore = new MintStore(mintPhases[0])
      }
      if (walletStore.account) yield this.fetchUserState(walletStore.account)
    } catch (e) {
      snackController.commonError(e)
    } finally {
      loadingController.decreaseRequest()
    }
  }

  @asyncAction *fetchMintTransactions(id) {
    const mintTrans = yield apiService.mintTransaction.find({ phase: id, mintType_in: ['public-mint', 'pre-mint'] })
    this.totalMintParticipant = this.forceMintParticipant || uniqBy(mintTrans, 'walletAddress').length
    this.totalPreMintNftSold =
      this.forcePreMintNftSold || mintTrans.filter((item) => item.mintType === 'pre-mint').length
    this.totalPublicMintNftSold =
      this.forcePublicMintNftSold || mintTrans.filter((item) => item.mintType === 'public-mint').length
  }

  @asyncAction *sendOptInMintApplication() {
    if (!walletStore.verifyConnectingWallet()) return
    try {
      this.isOptInLoading = true
      if (this.nftMintHandler) {
        yield this.nftMintHandler?.sendOptInMintApplication(walletStore.account)
      }
      this.isOptedInApplication = true
    } catch (error: any) {
      snackController.error(error.msg || error.message)
    } finally {
      this.isOptInLoading = false
    }
  }

  @asyncAction *fetchUserState(walletAddress: string) {
    try {
      yield when(() => !!this.mintStore)
      // const applyRecord = yield this.mintStore?.getWhitelistApplyStatus(walletAddress)
      // this.isWhitelistRegistered = !!applyRecord
      // if (applyRecord) this.isWhitelistMember = applyRecord?.isWhitelist
      this.isWhitelistRegistered = true
      this.isOptedInApplication = yield this.nftMintHandler?.isOptedInApplication(walletAddress)
      this.isContractWhitelistMember =
        this.currentPhase === 'pre-mint' ? yield this.nftMintHandler?.isWhitelistMember(walletAddress) : true

      const pendingNFTs = yield this.nftMintHandler?.getNFTPendingAssetIds(walletAddress)
      this.pendingNFTs = pendingNFTs
      if (pendingNFTs) this.minted = true
      const userState = yield this.nftMintHandler?.getUserState(walletAddress)
      this.totalYourNFTMinted = userState?.totalNftMinted || 0
    } catch (error) {
      console.log(error)
    }
  }

  @action changeOpenClaimNftDialog(value) {
    this.openClaimNftDialog = value
  }

  @action.bound setMax() {
    this.mintNumber = this.mintNumberAvailable
    this.mintNumberError = ''
  }

  @computed get mintNumberAvailable() {
    return !this.isAirdrop
      ? Number(this.currentPhaseInfo.maxAllocation) - this.totalYourNFTMinted
      : this.pendingNFTs?.length
  }

  @asyncAction *registerWhitelist() {
    try {
      this.whitelistRegisterLoading = true
      if (this.mintStore && walletStore.account) {
        const res = yield this.mintStore.createWhitelistApply(walletStore.account)
        if (res) {
          snackController.success('You registered whitelist successfully!')
          this.isWhitelistRegistered = true
        }
      }
    } catch (error) {
      snackController.commonError(error)
    } finally {
      this.whitelistRegisterLoading = false
    }
  }

  @action changeMintNumber() {
    this.mintNumberError = ''
  }

  @asyncAction *mintNFT() {
    try {
      if (this.mintNumber <= 0 || this.mintNumber > this.mintNumberAvailable) {
        this.mintNumberError = 'Invalid amount'
        return
      }
      this.minting = true
      yield this.nftMintHandler?.mintNFT(walletStore.account, Number(this.mintNumber), 'USDC')
      this.minted = true
      const pendingNFTs = yield this.nftMintHandler?.getNFTPendingAssetIds(walletStore.account)
      this.pendingNFTs = pendingNFTs
      // if (pendingNFTs.length) {
      //   for (let i = 0; i < pendingNFTs.length; i++) {
      //     const asset = yield getAssetByID(pendingNFTs[i])
      //     const mintType = this.currentPhase
      //     yield apiService.noteNfts.createNFTMetadata({
      //       assetID: pendingNFTs[i],
      //       mintType: mintType || '',
      //       walletAddress: walletStore.account,
      //       distribute: 'nft-sale',
      //       index: Number(asset.params['unit-name'].replace('TKN', '')),
      //       phaseId: this.mintPhase?.id,
      //     })
      //   }
      //   snackController.success('You have minted NFT successfully')
      // }
      this.totalYourNFTMinted += 1
      this.changeOpenClaimNftDialog(true)
    } catch (error) {
      snackController.commonError(error)
    } finally {
      this.minting = false
    }
  }

  @asyncAction *claimNFT(assetID: number, itemIndex) {
    if (!walletStore.verifyConnectingWallet() || this.claiming) return
    try {
      this.claimingNftIndex = itemIndex
      console.log('pendingNft', this.pendingNFTs)
      this.claiming = true
      yield this.nftMintHandler?.claimNFT(walletStore.account, assetID)
      snackController.success(`You have claimed #${assetID} successfully`)
      this.pendingNFTs = this.pendingNFTs.filter((item) => item != assetID)
      if (!this.pendingNFTs.length) this.changeOpenClaimNftDialog(false)
    } catch (error) {
      snackController.commonError(error)
    } finally {
      this.claiming = false
    }
  }
  @computed get mintPhase() {
    return this.mintStore?.mintPhase
  }

  @computed get mintState() {
    return this.mintStore?.mintState
  }

  @computed get currentPhase() {
    return this.mintState?.currentPhase
  }

  @computed get coverUrl() {
    return this.mintPhase?.cover?.url
  }
  @computed get tradeToken() {
    return this.mintPhase?.tradeToken
  }
  @computed get totalNftSale() {
    return this.mintPhase?.totalNftSale
  }

  @computed get preMintData() {
    return this.mintPhase?.preMintData
  }
  @computed get publicMintData() {
    return this.mintPhase?.publicMintData
  }
  @computed get airdropData() {
    return this.mintPhase?.airdropData
  }

  @computed get saleStarted() {
    return this.mintState?.saleStarted
  }

  @computed get currentPhaseInfo() {
    let data, phaseName
    const currentPhase = this.mintState?.currentPhase

    if (currentPhase === 'pre-mint') {
      data = this.preMintData
      phaseName = 'Mystery Box Pre-mint'
    } else if (currentPhase === 'public-mint') {
      data = this.publicMintData
      phaseName = 'NFT Public'
    } else {
      data = this.airdropData
      phaseName = 'NFT Airdrop'
    }

    const result: {
      nftSale?: string
      nftPrice?: string
      maxAllocation?: string
      phaseName?: string
    } = {
      nftSale: data?.nftSale,
      nftPrice: data?.nftPrice,
      maxAllocation: data?.maxAllocation,
      phaseName,
    }
    return result
  }
  @computed get mintTimeline() {
    if (this.mintState?.currentPhase === 'pre-mint') {
      return [
        {
          name: 'Opt-in (Need 3-4 $ALGO in your wallet)',
          description: [
            { text: 'Every user who want to buy or airdrop Mystery Box, ' },
            { text: 'MUST Opt-in. ', isBold: true },
            { text: ' If this phase is skipped, you cannot mint or airdrop our NFT.' },
          ],
          isTooLongTitle: true,
          time: this.preMintData?.whitelistOptInStartTime,
        },
        {
          name: 'Pre-mint Lottery',
          description: [{ text: 'Are you whitelisted or not?' }],

          time: this.preMintData?.whitelistLotteryTime,
        },
        {
          name: 'Mint',
          description: [
            { text: 'User can buy up to three Mystery Boxes! ' },
            { text: 'Make sure you have $USDC in your wallet.', isBold: true },
          ],
          time: this.preMintData?.saleStartTime,
        },
        {
          name: 'Claim',
          description: [{ text: 'Anyone who bought our Mystery Boxes can claim.' }],
          isClaim: true,
        },
      ]
    } else if (this.mintState?.currentPhase === 'public-mint') {
      return [
        {
          name: 'The Sale Start',
          description: [
            { text: 'Make sure you have to ' },
            { text: 'Opt-in ', isBold: true },
            { text: 'to participate the NFT sale.' },
          ],
          time: this.publicMintData?.saleStartTime,
        },
        {
          name: 'The Sale End',
          description: [{ text: '' }],
          time: this.publicMintData?.saleEndTime,
        },
      ]
    } else {
      return [
        {
          name: 'The Airdrop Start',
          description: [{ text: 'Make sure you are on our airdrop list.' }],
          time: this.airdropData?.saleStartTime,
        },
        {
          name: 'The Airdrop End',
          description: [{ text: '' }],

          time: this.airdropData?.saleEndTime,
        },
      ]
    }
  }
  @computed get mintRoadMap() {
    return [
      {
        name: 'Pre-Mint',
        description: 'Only Whitelist Members can mint',
        nftSale: this.preMintData?.nftSale,
        startTime: this.preMintData?.saleStartTime,
        endTime: this.preMintData?.saleEndTime,
        status: this.mintState?.preMintStatus,
      },
      {
        name: 'Public-Mint',
        description: 'All users can mint',
        nftSale: this.publicMintData?.nftSale,
        startTime: this.publicMintData?.saleStartTime,
        endTime: this.publicMintData?.saleEndTime,
        status: this.mintState?.publicMintStatus,
      },
      {
        name: 'Airdrop',
        description: 'Those on the airdrop list can claim',
        nftSale: this.airdropData?.nftSale,
        startTime: this.airdropData?.saleStartTime,
        endTime: this.airdropData?.saleEndTime,
        status: this.mintState?.airdropStatus,
      },
    ]
  }

  @computed get currentRoadMapStep() {
    return this.currentPhase === 'normal-mint' ? 2 : this.currentPhase === 'public-mint' || this.isAirdrop ? 1 : 0
  }

  @computed get isNFTMintFilled() {
    return this.totalYourNFTMinted >= this.maxAllocation && this.pendingNFTs?.length === 0
  }

  @computed get remainAllocation() {
    return this.totalYourNFTMinted && this.maxAllocation
      ? this.maxAllocation - this.totalYourNFTMinted
      : this.currentPhaseInfo.maxAllocation
  }

  @computed get maxAllocation() {
    return Number(this.currentPhaseInfo?.maxAllocation) || 0
  }

  @computed get totalNFTMinted() {
    return this.contractStates?.totalNFTMinted || 0
  }

  @computed get nftRemaining() {
    const currentPhase = this.currentPhase
    if (currentPhase === 'pre-mint') {
      return Number(this.currentPhaseInfo?.nftSale) - this.totalNFTMinted
    } else if (currentPhase === 'public-mint') {
      if (this.mintStore?.mintPhase)
        return (
          ((Number(this.mintStore?.mintPhase?.preMintData?.nftSale) +
            Number(this.mintStore?.mintPhase?.publicMintData?.nftSale)) as number) - this.totalNFTMinted
        )
    }
  }

  @computed get nftTotalSale() {
    const currentPhase = this.currentPhase
    if (currentPhase && ['pre-mint', 'public-mint', 'airdrop'].includes(currentPhase)) {
      if (currentPhase !== 'pre-mint') return Number(this.totalNftSale || 0)
      return Number(this.currentPhaseInfo.nftSale)
    }
  }

  @computed get totalPay() {
    return this.isAirdrop ? 0 : this.mintNumber * Number(this.currentPhaseInfo.nftPrice)
  }

  @computed get isAirdrop() {
    return this.currentPhase === 'airdrop'
  }

  @computed get mintInfoDescription() {
    return this.currentPhase === 'pre-mint'
      ? 'Only whitelisted members can participate in this NFT sale phase.'
      : this.currentPhase === 'public-mint'
      ? 'Anyone can participate in this NFT sale phase.'
      : 'Those on the airdrop list can claim'
  }

  @computed get publicMintNftPrice() {
    return Number(this.publicMintData?.nftPrice || 0)
  }

  @computed get preMintNftPrice() {
    return Number(this.preMintData?.nftPrice || 0)
  }

  @computed get totalNftSold() {
    return this.totalPreMintNftSold + this.totalPublicMintNftSold
  }

  @computed get totalNftSoldValue() {
    return this.totalPublicMintNftSold * this.publicMintNftPrice + this.totalPreMintNftSold * this.preMintNftPrice
  }

  @computed get forcePreMintNftSold() {
    return Number(this.preMintData?.forceNftSold || 0)
  }

  @computed get forcePublicMintNftSold() {
    return Number(this.publicMintData?.forceNftSold || 0)
  }

  @computed get forceMintParticipant() {
    return Number(this.mintPhase?.data?.forceParticipant || 0)
  }
  @computed get disableClaim() {
    return this.isAirdrop && !this.pendingNFTs.length
  }

  @computed get isWalletOpenOptIn() {
    const account = walletStore.account?.toUpperCase()
    return account && this.walletsOpenOptIn.includes(account)
  }

  @computed get forceShowOptInProcress() {
    return !this.mintState?.saleStarted && this.isWalletOpenOptIn
  }
  @computed get forceHideMint() {
    return this.currentPhase === 'public-mint'
  }
}
