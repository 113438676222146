import { appProvider } from '@/app-provider'
import { MintPhaseModel } from '@/models/mint-phase-model'
import { apiService } from '@/services/api-services'
import { action, computed, observable } from 'mobx'
import { asyncAction } from 'mobx-utils'
import { getMintState } from './mint.business'

export class MintStore {
  @observable mintPhase: MintPhaseModel = {} as any
  @observable isWhitelistApply = false

  constructor(mintPhase: MintPhaseModel) {
    this.changeModel(mintPhase)
    // this.loadData()
  }

  @action.bound changeModel(mintPhase: MintPhaseModel) {
    this.mintPhase = mintPhase
  }

  static generate(mintPhase: MintPhaseModel) {
    return new MintStore(mintPhase)
  }

  @asyncAction *createWhitelistApply(walletAddress: string) {
    if (this.mintPhase.id)
      return yield apiService.mintingPhases.createWhitelistApply(this.mintPhase?.id, { walletAddress: walletAddress })
  }

  @asyncAction *getWhitelistApplyStatus(walletAddress: string) {
    if (this.mintPhase.id) {
      const applyRecords = yield apiService.mintWhitelistApplies.find({
        walletAddress: walletAddress,
        mintPhase: this.mintPhase.id,
      })
      if (applyRecords.length) return applyRecords[0]
      else return false
    }
  }

  @computed get mintState() {
    return getMintState(this.mintPhase, appProvider.currentTime)
  }
}
